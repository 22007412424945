var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.direction === 'left' ? _c('svg', {
    attrs: {
      "width": "6",
      "height": "10",
      "viewBox": "0 0 6 10",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M2.82823 5.00081L5.65723 2.17281L4.24323 0.757812L0.000226974 5.00081L4.24323 9.24381L5.65723 7.82881L2.82823 5.00081Z",
      "fill": "#012345"
    }
  })]) : _c('svg', {
    attrs: {
      "width": "6",
      "height": "10",
      "viewBox": "0 0 6 10",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M3.17177 5.00081L0.342773 2.17281L1.75677 0.757812L5.99977 5.00081L1.75677 9.24381L0.342773 7.82881L3.17177 5.00081Z",
      "fill": "#012345"
    }
  })]);
};
var staticRenderFns = [];
export { render, staticRenderFns };