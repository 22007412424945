var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    class: _vm.$style.partnerSlider
  }, [_c(_setup.SGroup, {
    class: _vm.$style.slider,
    attrs: {
      "swiper": _setup.swiperOptions
    }
  }, _vm._l(_vm.items, function (item, index) {
    return _c('div', {
      key: index,
      class: _vm.$style.slide
    }, [_c('div', {
      class: _vm.$style.partnerItem
    }, [_c('img', {
      attrs: {
        "src": item.image.aws,
        "alt": ""
      }
    })])]);
  }), 0), _vm._v(" "), _c('div', {
    class: _vm.$style.bottom
  }, [_c('div', {
    staticClass: "swiper-pagination swiper-pagination-progressbar pagination-line",
    class: _vm.$style.pagination
  }), _vm._v(" "), _c('div', {
    class: _vm.$style.arrows
  }, [_c('div', {
    staticClass: "btn-prev",
    class: [_vm.$style.arrow, _vm.$style.arrowPrev]
  }, [_c(_setup.IconLandingPartnerSliderArrow, {
    attrs: {
      "direction": "left"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "btn-next",
    class: [_vm.$style.arrow, _vm.$style.arrowNext]
  }, [_c(_setup.IconLandingPartnerSliderArrow, {
    attrs: {
      "direction": "right"
    }
  })], 1)])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };